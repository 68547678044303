import { FaDiscord, FaMedium, FaTelegramPlane, FaTwitter, FaFacebookSquare } from "react-icons/fa";

interface props {
    padding: string,
    commonClasses: string
}

const Footer = (props: props) => {
    return (
        <footer id="footer" className="text-white text-center pt-6 bg-blue-light bg-opacity-30">
            <div className="m-auto flex justify-between items-center w-60 text-blue-900 ">
                <a href="https://t.me/worldraiders" target="_blank" rel="noreferrer"
                    className="hover:text-blue-600">
                    <FaTelegramPlane size='2em' /></a>
                <a href="https://twitter.com/worldraiders_" target="_blank" rel="noreferrer"
                    className="hover:text-blue-600">
                    <FaTwitter size='2em' />
                </a>
                <a href="https://medium.com/@worldraiders" target="_blank" rel="noreferrer"
                    className="hover:text-blue-600">
                    <FaMedium size='2em' />
                </a>
                <a href="https://www.facebook.com/World-Raiders-101192755729628" target="_blank" rel="noreferrer"
                    className="hover:text-blue-600">
                    <FaFacebookSquare size='2em' />
                </a>
                <a href="https://discord.gg/tXbnhzJa8n" target="_blank" rel="noreferrer"
                    className="hover:text-blue-600">
                    <FaDiscord size='2em' className="mt-1" />
                </a>
            </div>
            <p className="mt-8">Copyright &copy; 2021 WorldRaiders inc.</p>
        </footer>
    )
}

export default Footer;