import Body from "./components/Body";
import Footer from './components/Footer'

function App() {
  return (
    <>
        <Body/>
        <Footer/>
    </>
  );
}

export default App;
