import { useRef, useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa' 


const Carousel = (props: any) => {
    const bigImage = useRef<number>(2);
    const maxDist = useRef<number>(0);
    const [imageTextNumber, setImageTextNumber] = useState<number>(4); //Number of child element to be rendered, for changing text along with images

    useEffect(() => {  //When images load, calculates initial distance maxDist between left and right elements to transform on rotation
        const getPositionAtCenter = (element: HTMLElement) => {
            const { top, left, width, height } = element.getBoundingClientRect();
            return {
                x: left + width / 2,
                y: top + height / 2
            };
        }
        const getDistanceBetweenElements = (a: HTMLElement, b: HTMLElement) => {
            const aPosition = getPositionAtCenter(a);
            const bPosition = getPositionAtCenter(b);

            return bPosition.x - aPosition.x;  //only horizontal distance
            //return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y); for full distance  
        }
        maxDist.current = getDistanceBetweenElements(document.querySelector('#carou1')!, document.querySelector('#carou3')!)
        //console.log('effect running')
    }, [props.ready])

    const handleLeftArrow = () => {   //Rotate elements left
        const carou1: HTMLElement = document.getElementById('carou1')!;
        const carou2: HTMLElement = document.getElementById('carou2')!;
        const carou3: HTMLElement = document.getElementById('carou3')!;
        carou1.style.zIndex = '10';
        carou2.style.zIndex = '10';
        carou3.style.zIndex = '10';
        //console.log(bigImage.current)
        if (bigImage.current === 1) {
            bigImage.current = 2;
            carou3.style.transform = 'translateX(0px) scale(0.75, 0.75)'; 
            carou2.style.transform = 'translateX(0px) scale(1, 1)';
            carou1.style.transform = 'translateX(0px) scale(0.75, 0.75)';
            carou3.style.zIndex = '0';
            setImageTextNumber(4);
            return;
        }
        if (bigImage.current === 2) {
            bigImage.current = 3;
            carou3.style.transform = `translateX(${-maxDist.current / 2}px) scale(1, 1)`
            carou2.style.transform = `translateX(${-maxDist.current / 2}px) scale(0.75, 0.75)`
            carou1.style.transform = `translateX(${maxDist.current}px) scale(0.75, 0.75)`
            carou1.style.zIndex = '0';
            setImageTextNumber(5);
            return;
        };
        if (bigImage.current === 3) {
            bigImage.current = 1;
            carou3.style.transform = `translateX(${-maxDist.current}px) scale(0.75, 0.75)`
            carou2.style.transform = `translateX(${maxDist.current / 2}px) scale(0.75, 0.75)`
            carou1.style.transform = `translateX(${maxDist.current / 2}px) scale(1, 1)`
            carou2.style.zIndex = '0';
            setImageTextNumber(3);
            return;
        };

    }

    const handleRightArrow = () => {    //Rotate elements right
        const carou1: HTMLElement = document.getElementById('carou1')!;
        const carou2: HTMLElement = document.getElementById('carou2')!;
        const carou3: HTMLElement = document.getElementById('carou3')!;
        carou1.style.zIndex = '10';
        carou2.style.zIndex = '10';
        carou3.style.zIndex = '10';
        //console.log(bigImage.current)
        if (bigImage.current === 1) {
            bigImage.current = 3;
            carou3.style.transform = `translateX(${-maxDist.current / 2}px) scale(1, 1)`
            carou2.style.transform = `translateX(${-maxDist.current / 2}px) scale(0.75, 0.75)`
            carou1.style.transform = `translateX(${maxDist.current}px) scale(0.75, 0.75)`
            carou2.style.zIndex = '0';
            setImageTextNumber(5);
            return;
        }
        if (bigImage.current === 2) {
            bigImage.current = 1;
            carou3.style.transform = `translateX(${-maxDist.current}px) scale(0.75, 0.75)`
            carou2.style.transform = `translateX(${maxDist.current / 2}px) scale(0.75, 0.75)`
            carou1.style.transform = `translateX(${maxDist.current / 2}px) scale(1, 1)`
            carou3.style.zIndex = '0';
            setImageTextNumber(3);
            return;
        };
        if (bigImage.current === 3) {
            bigImage.current = 2;
            carou3.style.transform = 'translateX(0px) scale(0.75, 0.75)';  //and then remove this conditional
            carou2.style.transform = 'translateX(0px) scale(1, 1)';
            carou1.style.transform = 'translateX(0px) scale(0.75, 0.75)';
            carou1.style.zIndex = '0';
            setImageTextNumber(4);
            return;
        };
    }
    const handleLeftBallClick = () => {
        if (imageTextNumber === 4 || imageTextNumber === 3) {
            handleLeftArrow();
            return;
        }
    }
    const handleRightBallClick = () => {
        if (imageTextNumber === 4 || imageTextNumber === 5) {
            handleRightArrow();
            return;
        }
    }
    const handleMiddleBallClick = () => {
        if (imageTextNumber === 3) {
            handleLeftArrow();
            return;
        }
        if (imageTextNumber === 5) {
            handleRightArrow();
            return;
        }
    
    }

    return (
        <>
            <div className="flex justify-around">
                <FaAngleRight className="self-center transform rotate-180 scale-250 sm:scale-150 ml-2 cursor-pointer select-none"
                    onClick={handleLeftArrow}
                />
                {props.children[0]}
                {props.children[1]}
                {props.children[2]}
                <FaAngleRight className="self-center transform scale-250 sm:scale-150 mr-2 cursor-pointer select-none"
                    onClick={(handleRightArrow)}
                />
            </div>
            <div className="m-auto w-16 md:w-24 flex justify-between pt-4 sm:pt-2 pb-4">
                <div className={`rounded-full w-2 h-2 cursor-pointer ${imageTextNumber === 5 ? 'bg-blue-800' : 'bg-white'}`}
                    onClick={(handleLeftBallClick)}
                    />
                <div className={`rounded-full w-2 h-2 cursor-pointer ${imageTextNumber === 4 ? 'bg-blue-800' : 'bg-white'}`}
                    onClick={(handleMiddleBallClick)}
                />
                <div className={`rounded-full w-2 h-2 cursor-pointer ${imageTextNumber === 3 ? 'bg-blue-800' : 'bg-white'}`} 
                    onClick={handleRightBallClick}
                    />
            </div>
            {props.children[imageTextNumber]} 
        </>
    )
}

export default Carousel;