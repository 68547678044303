import RoadmapElement from "../RoadmapComponents/RoadmapElement"

interface props {
    commonClasses: string
}

const RoadmapBlock = (props: props) => {
    return (
        <>
            <div id="roadmap" className="overflow-hidden bg-blue-light relative">
                    <p className="text-4xl text-yellow-600 font-bold text-center p-2 border-b-2 border-white m-auto w-48 rounded-lg mt-4">Roadmap</p>
                <div id="roadmapBackground" className={`bg-space bg-cover bg-fixed px-4 py-4 pt-44 -mt-36 md:px-0 m-auto text-white text-lg`}>
                    
                    <div className="m-auto grid grid-cols-2 relative text-sm sm:text-base">
                        <div className="h-full absolute w-1/2 border-r-2 border-white ml-px md:ml-1 "></div>
                        <RoadmapElement position="text-right" src="https://worldraiders.s3.us-east-1.amazonaws.com/planet gray.png"
                         classNameImage={`pl-4 pt-6 transition duration-1000 transform delay-150 `}
                         classNameText={`transition-opacity duration-1000 delay-200 `}
                        >
                            <p className="text-yellow-600 font-bold text-xl">Q3 2021</p>
                            <p>Inception -</p>
                            <p>Talent sourcing -</p>
                            <p>Game prototype -</p>
                        </RoadmapElement>
                        <RoadmapElement position="text-left" src="https://worldraiders.s3.us-east-1.amazonaws.com/planet pink.png"
                            classNameImage={`pr-4 pt-4 transition duration-1000 transform delay-150`}
                            classNameText={`transition-opacity duration-1000 delay-200 `}
                        >
                            <p className="text-yellow-600 font-bold text-xl">Q4 2021</p>
                            <p>- Art and NFTs preparation</p>
                            <p>- Smart contract preparation</p>
                            <p>- Website release</p>
                            <p className="hidden sm:inline-block">- Marketing and social media</p>
                            <p className="sm:hidden">- Marketing</p>
                            <p className="sm:hidden pl-3">and social media</p>
                            
                        </RoadmapElement>
                        <RoadmapElement position="text-right" src="https://worldraiders.s3.us-east-1.amazonaws.com/planet orange.png"
                            classNameImage={`pl-2 transition duration-1000 transform delay-150 `}
                            classNameText={`transition-opacity duration-1000 delay-200 `}
                        >
                            <p className="text-yellow-600 font-bold text-xl">Q1 2022</p>     
                            <p>Marketplace -</p>                
                            <p>NFT sale -</p>
                            <p>$WRAID IGO -</p>
                            <p>PC game demo -</p>

                        </RoadmapElement>
                        <RoadmapElement position="text-left" src="https://worldraiders.s3.us-east-1.amazonaws.com/planet green.png"
                            classNameImage={`pr-2 transition duration-1000 transform delay-150 `}
                            classNameText={`transition-opacity duration-1000 delay-200 `}
                        >
                            <p className="text-yellow-600 font-bold text-xl">Q2 2022</p>
                            <p>- PC game beta</p>
                            <p>- Pc game release</p>
                            <p>- Mobile beta</p>
                        </RoadmapElement>
                        <RoadmapElement position="text-right" src="https://worldraiders.s3.us-east-1.amazonaws.com/planet metallic.png"
                            classNameImage={`transition duration-1000 transform delay-150 `}
                            classNameText={`transition-opacity duration-1000 delay-200`}
                        >
                            <p className="text-yellow-600 font-bold text-xl">Q3 2022</p>
                            <p>Mobile game -</p>
                            <p>Ranking system -</p>
                            <p>Descentralization -</p>
                        </RoadmapElement>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoadmapBlock