import useElementOnScreen from "../BodyComponents/useElementOnScreen";

const RoadmapElement = (props: any) => {
    const [ref1, opacity1] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0.3
    });
    return (
        <>
            {props.position === 'text-left' && <img src={props.src} alt="Planet" className={`justify-self-end ${props.classNameImage} 
            ${opacity1 === 'opacity-100' ? 'rotate-15' : ''}`}  />}
            <div ref={ref1} className={`${props.position} p-4 ${props.classNameText} ${opacity1}`}>

                {props.children}

            </div>
            {props.position === 'text-right' && <img src={props.src} alt="Planet" className={`${props.classNameImage}
            ${opacity1 === 'opacity-100' ? '-rotate-15' : ''}`} />}
        </>
    )
}

export default RoadmapElement