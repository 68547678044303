import { useState, useEffect } from 'react'
import { FaTelegramPlane, FaTwitter, FaDiscord, FaBars, FaWindowClose, FaFacebookSquare } from 'react-icons/fa'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Header = () => {
    const [dropDownVisibility, setDropDownVisibility] = useState<boolean>(false);
    const [dropDownVisibilityMkt, setDropDownVisibilityMkt] = useState<boolean>(false);

    useEffect(() => {
        let timer = setTimeout(() => {
            setDropDownVisibilityMkt(false)
        }, 1500);
        return () => clearTimeout(timer)
    }, [dropDownVisibilityMkt])

    return (
        <div id="header-wrap" className="px-4 flex h-10 text-lg md:border-b-2 border-white justify-between left-0 right-0 z-20 items-center
          m-auto bg-transparent lg:mx-20 rounded-b-md">
            {/* left-0 right-0 doesnt work on IE 7< // adjust for sm and md*/}
            <div className="w-24">
                <img className="overflow-visible z-10 mt-8 md:mt-12 h-auto w-3/4 md:w-full" src="https://worldraiders.s3.us-east-1.amazonaws.com/Logo.png" alt="Logo" />
            </div>
            <div className="hidden md:flex justify-between items-center text-white">
                <AnchorLink href="#mainPanel"><p className="mx-2 hover:text-blue-400">Home</p></AnchorLink>
                <AnchorLink href="#roadmap"><p className="mx-2 hover:text-blue-400">Roadmap</p></AnchorLink>
                <a href="https://docs.worldraiders.com" target="_blank" rel="noreferrer" className="hover:text-blue-400">
                    <p className="mx-2 hover:text-blue-400">White paper</p>
                </a>
                <p className="mx-2 hover:text-blue-400 relative" onClick={() => setDropDownVisibilityMkt(!dropDownVisibilityMkt)}>
                    Marketplace
                    {dropDownVisibilityMkt && <p className="bg-gradient-to-br from-blue-900 to-gray-900 text-gray-300 
                        absolute left-0 -right-4 top-8 mt-px border-t-2 border-white text-sm px-2">
                        <span className="absolute w-1/12 border-t-2 -mt-0.5 -ml-2 border-yellow-600 animate-side-load" />
                        Coming Soon
                    </p>}
                </p>

            </div>
            <div className="hidden md:flex justify-between items-center w-32 self-center text-white">
                <a href="https://t.me/worldraiders" target="_blank" rel="noreferrer" className="hover:text-blue-400"><FaTelegramPlane /></a>
                <a href="https://twitter.com/worldraiders_" target="_blank" rel="noreferrer" className="hover:text-blue-400"><FaTwitter /></a>
                <a href="https://www.facebook.com/World-Raiders-101192755729628" target="_blank" rel="noreferrer" className="hover:text-blue-400"><FaFacebookSquare /></a>
                <a href="https://discord.gg/tXbnhzJa8n" target="_blank" rel="noreferrer" className="hover:text-blue-400"><FaDiscord /></a>
            </div>
            <FaBars className={`md:hidden cursor-pointer select-none text-white fixed right-4 z-50 ${dropDownVisibility ? 'hidden' : ''}`}
                onClick={() => setDropDownVisibility(!dropDownVisibility)}
            />
            <FaWindowClose className={`md:hidden cursor-pointer select-none text-white fixed right-4 z-50 ${dropDownVisibility ? '' : 'hidden'}`}
                onClick={() => setDropDownVisibility(!dropDownVisibility)}
            />
            {dropDownVisibility && //Drop down menu
                <ul className={`bg-gradient-to-br from-blue-900 to-gray-900 text-gray-300 md:hidden fixed z-50 top-8 right-0`}>
                    <AnchorLink href="#mainPanel"><li className="mx-2 py-1 border-b border-white">Home</li></AnchorLink>
                    <AnchorLink href="#roadmap"><li className="mx-2 py-1 border-b border-white">Roadmap</li></AnchorLink>
                    <a href="https://docs.worldraiders.com" target="_blank" rel="noreferrer" className="hover:text-blue-400">
                        <li className="mx-2 py-1 border-b border-white">White paper</li>
                    </a>
                    <AnchorLink href="#footer"><li className='mx-2 py-1 border-b border-white'>Social media</li></AnchorLink>
                    <li className="mx-2 py-1 relative" onClick={() => setDropDownVisibilityMkt(!dropDownVisibilityMkt)}>
                        Marketplace
                        {dropDownVisibilityMkt && <p className="bg-gray-700 text-gray-300 rounded-b-md
                        absolute -left-2 -right-4 top-8 mt-px px-2 text-sm">
                            <span className="absolute w-1/12 border-t-2 -ml-2 border-yellow-600 animate-side-load" />
                            Coming Soon
                        </p>}
                    </li>
                </ul>
            }
        </div>
    )
}

export default Header;