import { useState, useEffect, useRef } from 'react'
import AncientBlock from './BodyComponents/AncientBlock';
import CarouselBlock from './BodyComponents/CarouselBlock';
import SlideBlock from './BodyComponents/SlideBlock';
import RoadmapBlock from './BodyComponents/RoadmapBlock';
import Transition from './helpers/Transition';
import Header from './Header';
import Team from './Team';
import { FaAngleDoubleRight } from 'react-icons/fa'
import FadeOutOnScroll from './helpers/FadeOutOnScroll';


const Body = () => {
    const containerPadding = useRef<string>('px-4');
    const commonClasses = useRef<string>("2xl:max-w-4xl m-auto");
    const [imageSum, setImageSum] = useState<number>(0);
    const [bgHasLoaded, setBgHasLoaded] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);

    useEffect(() => {  //loads image first and then sets it as background
        let bg = document.getElementById('wrapper')
        let src = 'https://worldraiders.s3.us-east-1.amazonaws.com/bg-transp.png';
        let image = new Image();

        const onLoad = () => {
            bg!.style.backgroundImage = 'url(' + src + ')';
            setBgHasLoaded(true);
        };

        image.addEventListener('load', onLoad);
        image.src = src;
        //console.log(imageSum, bgHasLoaded)

        if (imageSum === 2 && bgHasLoaded) setShowSpinner(false) // Removes loader and show page

        return () => image.removeEventListener('load', onLoad);
    }, [imageSum, bgHasLoaded])

    /**Checks if astronaut and galaxy loaded for further spinner display*/
    const imageLoaded = (n: number) => {  
        setImageSum(imageSum + n);
    }

    return (
        <>
            {showSpinner &&
                <div className={`relative pt-24`}>
                    <div className="bg-loading-small sm:bg-loading bg-no-repeat w-48 sm:w-72 m-auto animate-pulse-slow
                    transform rotate-180 filter hue-rotate-90 " >
                        <img className="m-auto animate-spin-slow w-48 sm:w-auto" src="https://worldraiders.s3.us-east-1.amazonaws.com/asteroide.png" alt="Bottle" />
                    </div>
                    <p className="text-center pt-6 text-white animate-bounce">Loading</p>
                </div>
            }
            
            <div id="wrapper" className={`${showSpinner ? 'invisible' : 'visible'} overflow-x-hidden bg-cover bg-fixed `}>
                <Header />
                <div id="mainPanel" className={`px-4 md:px-20 2xl:container m-auto`}>
                    
                    <div id="rockets" className="w-3/4 md:w-3/5 mx-auto pt-24 md:pt-36">
                        <img src="https://worldraiders.s3.us-east-1.amazonaws.com/rockets.png" alt="Rockets"></img>
                    </div>
                    <div className="flex justify-between">
                        <Transition className="pt-36 w-96 md:w-96" translate="-translate-y-24">                        
                                <img src="https://worldraiders.s3.us-east-1.amazonaws.com/Astronaut.png" onLoad={() => imageLoaded(1)} alt="Astronaut"></img>
                        </Transition>
                        <Transition translate='-translate-y-36' 
                            className="w-1/4 text-white text-center m-auto hidden lg:block pt-32"
                            fadeIn={true}
                        >
                            <h1 className="text-xl font-bold">Welcome to world raiders</h1>
                            <p className="text-lg mt-px">Begin your path. Become the best</p>
                        </Transition>
                        <div id="planet" className="pt-24 w-96 md:w-96">
                            <img src="https://worldraiders.s3.us-east-1.amazonaws.com/Planet.png" onLoad={() => imageLoaded(1)} alt="Planet"></img>
                        </div>
                    </div>
                    <Transition translate='-translate-y-10'
                        className="m-auto pt-36 text-left md:text-center text-white lg:hidden"
                        fadeIn={true}
                    >
                        <p className="text-lg font-bold">Welcome to world raiders</p>
                        <p className="">Begin your path. Become the best</p>
                    </Transition>
                    <div className="transform rotate-90 absolute bottom-12 left-1/2 ">                      
                        <FadeOutOnScroll>
                            <FaAngleDoubleRight className="lg:hidden m-auto text-white animate-ping transform -translate-x-1/2 
                            "/>
                        </FadeOutOnScroll>                         
                    </div>
                </div>
                <div className="h-12 -mx-20 bg-gradient-to-b from-transparent to-blue-darkest opacity-95"></div>
                <AncientBlock padding={containerPadding.current} commonClasses={commonClasses.current}/>
                <CarouselBlock padding={containerPadding.current} commonClasses={commonClasses.current}/>
                <SlideBlock padding={containerPadding.current} commonClasses={commonClasses.current}/>              
                <RoadmapBlock commonClasses={commonClasses.current}/>
                <Team commonClasses={commonClasses.current}/>
            </div>
        </>
    )

}


export default Body;