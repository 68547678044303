import useElementOnScreen from "./useElementOnScreen";

interface props {
    padding: string,
    commonClasses: string
}

const SlideBlock = ({ padding, commonClasses }: props) => {

    const [ref2, opacity2] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0.3
    });
    const [ref3, opacity3] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0.3
    });
    const [ref4, opacity4] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0.3
    });
    return (
        <div id="thirdBlock" className="relative z-10">
            <div className={`bg-gradient-to-b from-blue-medium to-blue-light ${padding} lg:px-20`}>
                <div className={`${commonClasses}`}>
                    <div ref={ref2} className={`m-auto flex flex-col justify-items-center md:justify-start
                         text-white pt-16 md:flex-row transition duration-500 ${opacity2}`}>
                        <img className="m-auto max-h-60 max-w-xxs mb-2 md:mb-0 md:max-w-xs md:mx-0 rounded-md" src="https://worldraiders.s3.us-east-1.amazonaws.com/Mine.png" alt="Mine" />
                        <div className={`self-center text-center transform transition duration-700 
                            ${opacity2} ${opacity2 === 'opacity-100' ? '' : 'translate-x-1/2 md:translate-x-64'}`}>
                            <h1 className="mx-auto text-2xl font-bold text-yellow-600 mb-4 md:text-left md:ml-4">
                                Play to earn
                            </h1>
                            <p className="m-auto md:text-left md:mx-4 max-w-xxs md:max-w-4xl ">
                                Earn tradable rewards by mining your planet resources. The rarest your planet is, the more you earn. But protect yourself, or someone might get it from you...
                            </p>
                        </div>
                    </div>
                    <div ref={ref3} className={`m-auto flex flex-col-reverse justify-items-center md:justify-end
                        text-white pt-10 md:flex-row transition duration-500 ${opacity3}`}>
                        <div className={`self-center text-center transform transition duration-700
                            ${opacity3} ${opacity3 === 'opacity-100' ? '' : '-translate-x-1/2 md:-translate-x-64'}`}>
                            <h1 className="mx-auto text-2xl font-bold text-yellow-600 mb-4 md:text-right md:mr-4">
                                Steal to earn even more!
                            </h1>
                            <p className="m-auto md:text-right md:mx-4 max-w-xxs md:max-w-2xl transition duration-700 ">
                                Get your raider ready and try to invade your opponents' planet! If you succeed you can steal their rewards and, if you are lucky enough, even a shard of their planet
                            </p>
                        </div>
                        <img className="m-auto max-h-60 max-w-xxs mb-2 md:mb-0 md:max-w-xs md:mx-0" src="https://worldraiders.s3.us-east-1.amazonaws.com/Steal.png" alt="Steal" />
                    </div>
                    <div ref={ref4} className={`m-auto flex flex-col md:flex-row justify-items-center md:justify-start
                         text-white pt-10 transition duration-500 ${opacity4}`}>
                        <img className="m-auto max-h-60 max-w-xxs md:max-w-xs md:mx-0 mb-2 md:mb-8" src="https://worldraiders.s3.us-east-1.amazonaws.com/Defense.png" alt="Defense" />
                        <div className={`self-center text-center transform transition duration-700 
                            ${opacity4} ${opacity4 === 'opacity-100' ? '' : 'translate-x-1/2 md:translate-x-64'}`}>
                            <h1 className="mx-auto text-2xl font-bold text-yellow-600 mb-4 md:text-left md:ml-4">
                                Build your own defenses
                            </h1>
                            <p className="m-auto mb-8 md:text-left md:mx-4 max-w-xxs md:max-w-2xl  transition duration-700 ">
                                Don't let anyone land on your planet and steal your resources. Use your planet's elements and build the perfect trap for your enemies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideBlock;