import { useRef, useState } from "react";
import Carousel from "./Carousel";

interface props {
    padding: string,
    commonClasses: string
}

const CarouselBlock = ({padding, commonClasses}: props) => {
    const nftSum = useRef<number>(0);
    const [allLoaded, setNftsLoaded] = useState<boolean>(false);

    /** Checks if all three images from the carousel have already loaded to
    * avoid distance measurement on carousel to run before images are loaded */
    const nftsLoaded = (n: number) => {
        nftSum.current += n;
        if (nftSum.current === 6) setNftsLoaded(true);
    }
    return (
        <div id="secondBlock"
            className={`relative bg-gradient-to-b py-8 from-blue-dark to-blue-medium ${padding}`}>
            <div className={`absolute w-4/5 lg:w-3/5 ${commonClasses} border-t-2 border-white top-0 left-1/2 transform -translate-x-1/2 border-opacity-50`} />
            <div className={`absolute w-4/5 lg:w-3/5 ${commonClasses} border-t-2 border-white bottom-0 left-1/2 transform -translate-x-1/2 border-opacity-50`} />
            <div className="text-white w-11/12 md:w-3/4 lg:w-1/2 m-auto sm:h-64 md:h-80 text-center mb-2">
                <Carousel ready={allLoaded}>
                    <div id="carou1" className="m-4 duration-500 transform scale-75">
                        <img className="w-full max-h-96 md:max-h-60 transform scale-150 sm:scale-125 md:scale-100"
                            onLoad={() => nftsLoaded(1)} src="https://worldraiders.s3.us-east-1.amazonaws.com/Shard.png" alt="Shard" />
                    </div>
                    <div id="carou2" className="m-4 duration-500">
                        <img id="img3" className="w-full max-h-96 md:max-h-60 transform scale-150 sm:scale-125 md:scale-100 filter hue-rotate-60"
                            onLoad={() => nftsLoaded(2)} src="https://worldraiders.s3.us-east-1.amazonaws.com/Planet1.png" alt="Shard" />
                    </div>
                    <div id="carou3" className="m-4 duration-500 transform scale-75">
                        <img className="w-full max-h-96 md:max-h-60 transform scale-150 sm:scale-125 md:scale-100"
                            onLoad={() => nftsLoaded(3)} src="https://worldraiders.s3.us-east-1.amazonaws.com/Bottle.png" alt="Bottle" />
                    </div>
                    <p className={`transition duration-500 mt-4 sm:mt-0 ${commonClasses}`}>Collect different types of shards to create planets with different looks, mass, size and defense mechanisms. Rare shards can get you special planets!</p>
                    <p className={`transition duration-500 mt-4 sm:mt-0 ${commonClasses}`}>Get your planet NFT and start mining its resources. Create your very own defense strategy using different elements that come along with your planet</p>
                    <p data-testid="secondary-text" className={`transition duration-500 mt-4 sm:mt-0 ${commonClasses}`}>Those who dare to attack you and die might leave some essence behind. Gather their essences to upgrade your raiders with the help of the Ancient Architect</p>
                </Carousel>
            </div>
        </div>
    )
}

export default CarouselBlock;