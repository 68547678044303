import { FaLinkedin, FaTelegram } from 'react-icons/fa'

interface props {
    commonClasses: string
}

const Team = (props: props) => {
    return (
        <div className="bg-gradient-to-b from-blue-light to-blue-medium">
            <div className={`flex justify-evenly m-auto flex-wrap pb-8`}>
                <div className="pt-8 text-center text-white text-lg w-72 lg:w-96 relative group">
                    <a href="https://www.linkedin.com/in/pedroalbani" target="_blank" rel="noreferrer" className="hover:text-blue-400">
                        <FaLinkedin className="absolute top-0 left-1/2 ml-24 mt-4 w-6 h-6 transition-opacity duration-500
                     lg:opacity-0 group-hover:opacity-100 cursor-pointer" />
                    </a>
                    <img className="w-48 h-48 m-auto" src="https://worldraiders.s3.us-east-1.amazonaws.com/pedro.png" alt="Pedro" />
                    <p className="pt-2 text-yellow-600 font-bold text-xl">Pedro Albani</p>
                    <p>CEO</p>
                </div>
                <div className="pt-8 text-center text-white text-lg w-72 lg:w-96 ">
                    <img className="w-48 h-48 m-auto" src="https://worldraiders.s3.us-east-1.amazonaws.com/afonso.png" alt="Afonso" />
                    <p className="pt-2 text-yellow-600 font-bold text-xl">Afonso Salles</p>
                    <p>CTO</p>
                </div>
                <div className="pt-8 text-center text-white text-lg w-72 lg:w-96">
                    <img className="w-48 h-48 m-auto" src="https://worldraiders.s3.us-east-1.amazonaws.com/cid.png" alt="Cid" />
                    <p className="pt-2 text-yellow-600 font-bold text-xl">Cid Martins</p>
                    <p>COO</p>
                </div>
                <div className="pt-8 text-center text-white text-lg w-72 lg:w-96 relative group">
                    <a href="https://t.me/hugomachad" target="_blank" rel="noreferrer" className="hover:text-blue-400">
                        <FaTelegram className="absolute top-0 left-1/2 ml-24 mt-4 w-6 h-6 transition-opacity duration-500
                     lg:opacity-0 group-hover:opacity-100 cursor-pointer" />
                    </a>
                    <img className="w-48 h-48 m-auto" src="https://worldraiders.s3.us-east-1.amazonaws.com/hugo.png" alt="Hugo" />
                    <p className="pt-2 text-yellow-600 font-bold text-xl">Hugo Machado</p>
                    <p>CMO</p>
                </div>
            </div>
        </div>
    )
}

export default Team