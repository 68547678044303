import { useState, useEffect } from 'react'
import useElementOnScreen from "../BodyComponents/useElementOnScreen";

/** Wrapper component for transitioning on the first scroll
 * @params {fadeIn: bool - default false}
 * @params {className: string - wrapper className}
 * @params {translate: string - tailwind translation class}
 */
const Transition = (props) => {
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = (e) => {
            if (e.target.documentElement.scrollTop > 0 && scrollTop > 0) return;  //avoids multiple rerenders
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);


    const [ref, opacity] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    })

    return (
        <div ref={ref} className={`${props.className}  ${props.fadeIn 
            ?   //if fadeIn is true, children fades in and translates
                ` transition duration-1000 transform ${scrollTop ? props.translate : ''}
                ${(opacity === 'opacity-100' && scrollTop !== 0) ? 'opacity-100' : 'opacity-0'}`
                
            :   //otherwise only translates
                ` transition duration-1000 transform ${scrollTop ? props.translate : ''}`
            }`}
        >
            {props.children}
        </div>
    )
}

export default Transition;