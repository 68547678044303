import useElementOnScreen from "./useElementOnScreen";
// import {useEffect} from 'react'

interface props {
    padding: string,
    commonClasses: string
}

const AncientBlock = ({padding, commonClasses}: props)=> {
    const [ref, , isVisible] = useElementOnScreen({  //On startup isVisible is alredy set to true. When using hook opacity element is always visible
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    });
    // useEffect(() => {
    //     console.log(isVisible)
    // }, [isVisible])

    return (
        <div id="firstBlock" className="relative">
            <div className={`bg-gradient-to-b pt-8 pb-16 from-blue-darkest to-blue-dark px-4 md:px-20
                 ${padding}`}>
                <div className={`flex justify-between m-auto ${commonClasses}`}>
                    <div className={`w-full md:max-w-1/2 text-white md:text-xl
                            self-center transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                        <p ref={ref} className="">The ancient architect is the source of all wisdom. He will assist you in your journey by allowing you to create new planets and raiders.</p>
                    </div>
                    <img  className="w-64 max-h-48 md:max-h-80" src="https://worldraiders.s3.us-east-1.amazonaws.com/Ancient.png" alt="Ancient" />
                </div>
            </div>
        </div>
    )
}

export default AncientBlock;