import { useState, useEffect } from "react";

const FadeOutOnScroll = (props) => {
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = (e) => {
            if (e.target.documentElement.scrollTop > 0 && scrollTop > 0) return;  //avoids multiple rerenders
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return (
        <>
            {!scrollTop && props.children}
        </>
    )
}

export default FadeOutOnScroll;