import { useRef, useEffect, useState } from "react"

interface options {
    root: any,           //root element def body
    rootMargin: string,  //offSet distance in px 
    threshold: number    //threshold value 0~1
}
/** Return true if ref is inside the viewport + opacity helper class of tailwind
 * @returns {containerRef, opacity: string, isVisible: boolean}
 */
const useElementOnScreen = (options: options) => {   //
    const containerRef: any = useRef(null)
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [opacity, setOpacity] = useState<string>('opacity-0')

    const callbackFunction = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
        //console.log(entry.intersectionRatio)
    }

    useEffect(() => {
        let ref = containerRef.current
        const observer = new IntersectionObserver(callbackFunction, options)
        if (ref) observer.observe(ref)

        if (isVisible) {
            setOpacity('opacity-100')
        } else {
            //setOpacity('opacity-0')    //after it fades in wont fade out again
        }
        return () => {
            if (ref) observer.unobserve(ref)
        }
    }, [containerRef, options, isVisible])

    return [containerRef, opacity, isVisible]
}

export default useElementOnScreen;